<template>
  <div>
    <div class="container-frame">
      <div class="container-text-title-top container-item">
        <span
          class="msg-card-title title-black"
          v-html="messageItem.messageResponse.content.trackList.Title"
        >
          {{ messageItem.messageResponse.content.trackList.Title }}
        </span>
      </div>
      <hr />

      <div class="container-item">
        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.trackList
                    .IconTrackingPerson +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              v-html="
                messageItem.messageResponse.content.trackList.TrackingPerson
              "
              class="info"
            >
              {{ messageItem.messageResponse.content.trackList.TrackingPerson }}
            </span>
          </div>
        </div>

        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.trackList
                    .IconTrackingCountDetail +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              class="info"
              v-html="
                messageItem.messageResponse.content.trackList
                  .TrackingCountDetail
              "
            >
              {{
                messageItem.messageResponse.content.trackList
                  .TrackingCountDetail
              }}</span
            >
          </div>
        </div>

        <div
          class="container-item container-content"
          v-bind:style="{ borderColor: primaryColor }"
        >
          <span
            class="number-list"
            v-html="messageItem.messageResponse.content.trackList.LastCountList"
            >{{
              messageItem.messageResponse.content.trackList.LastCountList
            }}</span
          >

          <div
            v-for="(statusItem, j) in messageItem.messageResponse.content
              .trackList.Tracking"
            :key="j"
            class="item-list"
            @click="
              () => {
                sendTrackingNo(
                  statusItem.TrackingPostback,
                  statusItem.TrackingNo
                );
              }
            "
          >
            <div class="row-item">
              <div class="display-line">
                <div
                  class="img-icon-display"
                  v-bind:style="{
                    backgroundImage: 'url(' + statusItem.IconTrackingNo + ')',
                  }"
                ></div>
                <div class="info-section">
                  <span class="info-trackNo" v-html="statusItem.TrackingNo">
                    {{ statusItem.TrackingNo }}</span
                  >
                </div>
              </div>
            </div>

            <div class="row-item">
              <div class="display-line">
                <div
                  class="img-icon-display"
                  v-bind:style="{
                    backgroundImage: 'url(' + statusItem.IconStatus + ')',
                  }"
                ></div>
                <div class="info-section">
                  <span
                    class="info-status"
                    v-html="statusItem.StatusName"
                    v-bind:style="{ color: primaryColor }"
                  >
                    {{ statusItem.StatusName }}</span
                  >
                </div>
              </div>
            </div>

            <div class="row-item">
              <div class="display-line">
                <div
                  class="img-icon-display"
                  v-bind:style="{
                    backgroundImage: 'url(' + statusItem.IconDeliveryTime + ')',
                  }"
                ></div>
                <div class="info-section">
                  <span
                    class="info-delivery-time"
                    v-html="statusItem.DeliveryTime"
                  >
                    {{ statusItem.DeliveryTime }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="container-btn">
            <button
              type="button"
              class="list-btn"
              v-bind:style="{ backgroundColor: primaryColor }"
              @click="
                () => {
                  handleClickButton(
                    messageItem.messageResponse.content.trackList.Button.Action,
                    messageItem.messageResponse.content.trackList.Button.Label,
                    messageItem.messageResponse.content.trackList.Button.Data
                  );
                }
              "
            >
              {{ messageItem.messageResponse.content.trackList.Button.Label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "../config.js";

export default {
  name: "ChatBoxTrackingList",
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: this.dynamicConfig.color,
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    sendTrackingNo(trackPostback, showTrackNo) {
      var dataSend = { text: trackPostback };
      this.$emit("send-postback-trackno", dataSend, 2, showTrackNo);
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
}
.container-frame {
  padding-top: 10px;
  padding-bottom: 5px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
.list-btn {
  border-radius: 0.5rem;
  margin-bottom: 5px;
  color: white;
  border: 0px;
  padding: 0.4rem;
  font-size: 20px;
  font-family: "DBHeavent";
}
.list-btn:hover {
  cursor: pointer;
}
.column {
  display: flex;
  flex-flow: column;
}
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  margin-bottom: 10px;
}
.container-text-title-top {
  margin-top: 0px;
}
.title-black {
  color: #3a3937;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.lower-row {
  font-size: 20px;
}
.display-line {
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.icon-section {
  width: 9%;
  justify-content: center;
  display: flex;
}
.img-icon-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 21px;
  width: 15px;
}
.info {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  color: #595a5c;
  display: block;
}
.info-delivery-time {
  word-wrap: break-word;
  margin-left: 0%;
  font-size: 20px;
  color: #595a5c;
}
.info-status {
  word-wrap: break-word;
  margin-left: 0%;
  font-size: 20px;
}
.info-trackNo {
  color: #595a5c;
  font-size: 20px;
}
.info-section {
  width: 90%;
  display: flex;
  padding-left: 4px;
}
.text-tel {
  text-decoration: underline;
  color: rgb(81, 112, 199);
}
.text-tel:hover {
  cursor: pointer;
}
.container-content {
  border-radius: 5px;
  border: black 1px solid;
  padding-top: 10px;
}
.item-list {
  margin-bottom: 10px;
  margin-top: 10px;
}
.item-list:hover {
  cursor: pointer;
}
.item-track-no {
  width: 60%;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.row-item {
  display: flex;
  justify-content: space-between;
}
.img-icon-track-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 13px;
}
.img-icon-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 13px;
}
.img-icon-status-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 13px;
  margin-top: 16px;
  margin-left: 3px;
}
.item-status {
  overflow-wrap: break-word;
  width: 40%;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  align-items: center;
  text-align: end;
}
.icon-go {
  margin-left: 5px;
}
.item-time {
  margin-left: 3px;
}
.time-round {
  padding-left: 30px;
}
.container-btn {
  display: flex;
  flex-flow: column;
  margin-top: auto;
}
.number-list {
  font-weight: bold;
  color: #3a3937;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}

@media (max-width: 767.98px) {
  .icon-section {
    justify-content: center;
    display: flex;
  }
  .info-section {
    width: 95%;
    margin-left: 8px;
    padding-left: 0px;
  }
  .item-status {
    width: 56%;
    padding-right: 5px;
  }
  .number-list {
    font-size: 20px;
  }
  .info-delivery-time {
    margin-left: 0%;
    font-size: 20px;
  }
  .img-icon-status-display {
    margin-top: 1px;
    margin-left: 0px;
  }
  .info-trackNo {
    font-size: 20px;
  }
  .info-status {
    font-size: 20px;
  }
}
</style>
