<template>
  <div class="container-frame container-item">
    <!-- Type 10 Tracking -->
    <div class="wrap-product-img">
      <div
        class="product-img"
        v-bind:style="{
          backgroundImage: `url(&quot;${messageItem.messageResponse.content.statusTracking.IconTitle}&quot;)`,
        }"
      ></div>
    </div>
    <div class="container-text">
      <span
        v-html="messageItem.messageResponse.content.statusTracking.Title"
        class="msg-card-title"
        v-bind:style="[{ color: primaryColor }]"
        >{{ messageItem.messageResponse.content.statusTracking.Title }}</span
      >
    </div>
    <div
      v-if="
        messageItem.messageResponse.content.statusTracking.StatusId == 0 &&
          messageItem.messageResponse.content.statusTracking.Detail
      "
      class="container-text"
    >
      <span
        v-html="messageItem.messageResponse.content.statusTracking.Detail"
        class="msg-card-sub-title"
        >{{ messageItem.messageResponse.content.statusTracking.Detail }}</span
      >
    </div>

    <div
      class="content-center"
      v-if="messageItem.messageResponse.content.statusTracking.StatusId == 1"
    >
      <div class="info-section">
        <div class="display-line">
          <div class="container-img-icon">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.statusTracking.Tracking
                    .IconTrackingNo +
                  ')',
              }"
            ></div>
          </div>
          <div class="container-info">
            <span
              v-html="
                messageItem.messageResponse.content.statusTracking.Tracking
                  .TrackingNo
              "
              class="info"
            >
              {{
                messageItem.messageResponse.content.statusTracking.Tracking
                  .TrackingNo
              }}
            </span>
          </div>
        </div>

        <div class="display-line">
          <div class="container-img-icon">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.statusTracking.Tracking
                    .IconStatus +
                  ')',
              }"
            ></div>
          </div>
          <div class="container-info">
            <span
              v-html="
                messageItem.messageResponse.content.statusTracking.Tracking
                  .StatusName
              "
              class="info"
            >
              {{
                messageItem.messageResponse.content.statusTracking.Tracking
                  .StatusName
              }}
            </span>
          </div>
        </div>

        <div class="display-line">
          <div class="container-img-icon">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.statusTracking.Tracking
                    .IconDeliveryTime +
                  ')',
              }"
            ></div>
          </div>
          <div class="container-info">
            <span
              v-html="
                messageItem.messageResponse.content.statusTracking.Tracking
                  .DeliveryTime
              "
              class="info"
            >
              {{
                messageItem.messageResponse.content.statusTracking.Tracking
                  .DeliveryTime
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-btn"
      v-if="messageItem.messageResponse.content.statusTracking.StatusId == 1"
    >
      <button
        v-if="
          messageItem.messageResponse.content.statusTracking.Button.Action != 8
        "
        type="button"
        class="list-btn"
        v-bind:style="{ backgroundColor: primaryColor }"
        @click="
          () => {
            handleClickButton(
              messageItem.messageResponse.content.statusTracking.Button.Action,
              messageItem.messageResponse.content.statusTracking.Button.Label,
              messageItem.messageResponse.content.statusTracking.Button.Data
            );
          }
        "
      >
        {{ messageItem.messageResponse.content.statusTracking.Button.Label }}
      </button>

      <a
        v-else
        class="list-btn btn-tel"
        v-bind:style="{ backgroundColor: themeColor }"
        :href="`tel:${btn.Label}`"
      >
        {{ btn.Label }}
      </a>
    </div>
  </div>
</template>

<script>
// import config from "../config.js";

export default {
  name: "ChatBoxTracking",
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: this.dynamicConfig.color,
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-frame {
  padding-top: 10px;
  padding-bottom: 10px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
.wrap-product-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-img {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}
.container-img-icon {
  width: 10%;
}
.container-info {
  width: 90%;
  padding: 0 3px;
}
.img-icon-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 21px;
}
.display-line {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 2px 0px;
}
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #3a3937;
}
.msg-card-sub-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
}
.container-text {
  margin-top: 8px;
}
.container-btn {
  display: flex;
  flex-flow: column;
  margin-top: 8px;
}
.list-btn {
  border-radius: 0.5rem;
  color: white;
  border: 0px;
  padding: 0.4rem;
  font-size: 20px;
  font-family: "DBHeavent";
}
.list-btn:hover {
  cursor: pointer;
}
.btn-tel {
  text-decoration-line: none;
  text-align: center;
}
.info {
  margin-left: 5px;
  font-size: 20px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  color: #595a5c;
  display: block;
}
.info-section {
  margin: 5px 0;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89,90,92, 0.25);
  outline: none;
}

@media (max-width: 767.98px) {
  .info {
    font-size: 20px;
  }
  .container-img-icon {
    justify-content: center;
    display: flex;
  }
}
</style>
