<template>
  <div>
    <div class="container-frame">
      <div class="container-item">
        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.message.IconTracking +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              v-html="messageItem.messageResponse.content.message.MyTracking"
              class="info"
            >
              {{ messageItem.messageResponse.content.message.MyTracking }}
            </span>
          </div>
        </div>

        <div class="lower-row display-line">
          <div class="icon-section">
            <div
              class="img-icon-display"
              v-bind:style="{
                backgroundImage:
                  'url(' +
                  messageItem.messageResponse.content.message.IconAddress +
                  ')',
              }"
            ></div>
          </div>
          <div class="info-section">
            <span
              class="info"
              v-html="messageItem.messageResponse.content.message.Address"
            >
              {{ messageItem.messageResponse.content.message.Address }}</span
            >
          </div>
        </div>
      </div>
      <hr />
      <div class="container-item">
        <div class="wrap-product-img">
          <div
            class="product-img"
            v-bind:style="{
              backgroundImage:
                'url(' +
                messageItem.messageResponse.content.message.QRCodeUrl +
                ')',
            }"
          ></div>
        </div>

        <div class="booking-no">
          {{ messageItem.messageResponse.content.message.FooterTracking }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "../config.js";

export default {
  name: "ChatBoxTrackingList",
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: this.dynamicConfig.color,
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    sendTrackingNo(trackNo) {
      var dataSend = { text: trackNo };
      this.$emit("send-message", dataSend, 1);
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
}
.container-frame {
  padding-top: 10px;
  padding-bottom: 10px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
.column {
  display: flex;
  flex-flow: column;
}
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  margin-top: 0px;
  margin-bottom: 10px;
}
.container-text-title-top {
  margin-top: 0px;
}
.title-black {
  color: #3a3937;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.lower-row {
  font-size: 20px;
}
.display-line {
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
.icon-section {
  width: 9%;
  justify-content: center;
  display: flex;
}
.img-icon-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 16px;
}
.info {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  color: #595a5c;
  display: block;
}
.info-section {
  width: 90%;
  display: flex;
  padding-left: 4px;
}
.text-tel {
  text-decoration: underline;
  color: rgb(81, 112, 199);
}
.text-tel:hover {
  cursor: pointer;
}
.container-content {
  border-radius: 5px;
  border: black 1px solid;
  padding-top: 10px;
}
.item-list {
  margin-bottom: 10px;
  margin-top: 10px;
}
.item-list:hover {
  cursor: pointer;
}
.row-item {
  display: flex;
  justify-content: space-between;
}
.img-icon-track-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 13px;
  margin-top: 2px;
}
.img-icon-status-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 16px;
  height: 13px;
  margin-top: 16px;
  margin-left: 3px;
}
.item-time {
  margin-left: 3px;
}
.time-round {
  padding-left: 30px;
}
.container-btn {
  display: flex;
  flex-flow: column;
  margin-top: auto;
}
.booking-no {
  font-weight: bold;
  color: #3a3937;
  text-align: center;
  font-size: 20px;
}
.wrap-product-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px 13px 0px;
}
.product-img {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
  border: 1px solid #bebebe;
}

@media (max-width: 767.98px) {
  .icon-section {
    justify-content: center;
    display: flex;
  }
  .info-section {
    width: 95%;
    margin-left: 8px;
    padding-left: 0px;
  }
}
</style>
