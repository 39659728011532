<template>
  <div class="form">
    <div>
      <div class="alert" v-if="isError">
        <font-awesome-icon icon="exclamation-circle" />
        ไม่สำเร็จ
      </div>
    </div>
    <form v-on:submit.prevent="sendForm">
      <div v-for="(item, name) in field" :key="name">
        <InputText
          :value="item.value"
          :textFloat="cookieLang == 'th' ? item.label : item.labelEn"
          @onDataChange="
            (val) => {
              $v.form[name].$model = val;
              field[name].value = val;
            }
          "
          :placeholder="cookieLang == 'th' ? item.label : item.labelEn"
          :name="item.fieldName"
          :type="item.type"
          :isRequired="item.required"
          :isValidate="$v.form[name].$error"
          :v="$v.form[name]"
        />
      </div>

      <div v-if="isLoading" class="container-loader">
        <div class="loader" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-if="buttonTitle" class="container-btn">
        <button
          type="submit"
          class="btn-chat"
          v-bind:style="[{ backgroundColor: dynamicConfig.color }]"
        >
          {{ buttonTitle }}
        </button>
      </div>

      <div v-else class="container-btn-icon">
        <button
          type="submit"
          v-bind:style="[{ color: dynamicConfig.color, border: 0 }]"
          class="btn-icon-chat"
        >
          <font-awesome-icon icon="arrow-circle-right" id="iconChat" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import config from "./config.js";
import InputText from "../input/InputText.vue";

export default {
  name: "ChatForm",
  props: {
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  components: {
    InputText,
  },
  mounted() {
    this.cookieLang = this.$cookies.isKey("next-i18next")
      ? this.$cookies.get("next-i18next")
      : "th";
    document.getElementById("iconChat").style.fontSize = 25 + "px";
  },
  data() {
    return {
      config: config,
      buttonTitle: config.preChatForm.buttonTitle,
      field: config.preChatForm.field,
      form: {
        brandId: this.dynamicConfig.brandId,
        platformId: this.dynamicConfig.platformId,
        channelReferenceId: this.dynamicConfig.channelReferenceId,
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        numberPhone: "",
      },
      isError: false,
      // primaryColor: this.dynamicConfig.color,
      isLoading: false,
      cookieLang: "",
    };
  },
  created() {
    if (this.$cookies.get("d-user-info")) {
      // go to chat page (ChatBox)
      this.$emit("setShowChatBox", true);
    }
  },
  validations() {
    return {
      form: this.config.preChatForm.validate,
    };
  },
  methods: {
    sendForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$v.form.$reset();

      // api register
      this.register();
    },
    async register() {
      this.isLoading = true;
      var webId = "";
      await this.$axios({
        method: "get",
        url: config.chatBaseUrl + "/web/setting/" + this.form.brandId,
        headers: {
          "API-KEY": config.apiKey,
          "Content-Type": "application/json",
        },
      }).then((result) => {
        if (result.data.result == 1) {
          webId = result.data.detail.webId;
        } else {
          // pop up error
          this.handleLoadError();
        }
      });

      var data = {
        brandId: this.form.brandId,
        platformId: this.form.platformId,
        channelReferenceId: webId,
        firstname: this.form.firstName,
        lastname: this.form.lastName,
        telephone: this.form.numberPhone,
        email: this.form.email,
      };

      this.$axios({
        method: "post",
        url: config.chatBaseUrl + "/user/register",
        data: data,
        headers: {
          "API-KEY": config.apiKey,
          "Content-Type": "application/json",
        },
      }).then((result) => {
        if (result.data.result == 1) {
          this.isError = false;

          this.form.userId = result.data.detail.userGUID;

          // set userId in config file
          config.userId = result.data.detail.userGUID;

          // set cookies
          this.$cookies.set("d-user-info", this.form);

          this.isLoading = false;

          // go to chat page (ChatBox)
          this.$emit("setShowChatBox", true);
        } else {
          // pop up error
          this.handleLoadError();
        }
      });
    },
    handleLoadError() {
      this.isError = true;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 0.7rem 1rem 0.7rem 1rem;
}
.btn-chat {
  border: none;
  border-radius: 0.5rem;
  padding: 7px;
  color: white;
  font-size: 15px;
}
.btn-chat:hover {
  cursor: pointer;
}
.container-btn {
  display: grid;
}
.container-btn-icon {
  display: flex;
  justify-content: flex-end;
}
.btn-icon-chat {
  background-color: inherit;
}
.btn-icon-chat svg {
  height: 1.4rem !important;
  width: auto;
}
.btn-icon-chat:hover {
  cursor: pointer;
}
.alert {
  margin-bottom: 8px;
  color: red;
}
.container-loader {
  display: flex;
  justify-content: center;
  width: 100%;
}
.loader {
  border: 10px solid #dfd9d9;
  border-radius: 50%;
  border-top: 10px solid #757575;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin-right: 1.5rem;
}
button:focus {
  box-shadow: none;
  outline: none;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767.98px) {
  .form {
    height: 100vh;
  }
}
</style>
