<template>
  <div class="container-map">
    <div class="modal-map-bar">
      <span class="title-map">Shared Your Location</span>
      <font-awesome-icon
        id="mapClose"
        icon="times"
        @click="hideMap"
        class="close-btn"
      />
    </div>
    <div class="map" v-if="showMap">
      <div class="input-location-name">
        <font-awesome-icon
          icon="search"
          v-bind:style="{ color: dynamicConfig.color }"
          class="icon-search"
        />
        <GmapAutocomplete
          position.sync="{ lat: myCoordinates.lat, lng: myCoordinates.lng }"
          @place_changed="setPlace"
          class="custom-input"
          placeholder="ค้นหาจุดที่ตั้งของคุณ"
        >
        </GmapAutocomplete>
      </div>

      <GmapMap
        :center="{ lat: myCoordinates.lat, lng: myCoordinates.lng }"
        :zoom="zoom"
        style="width:100%;height:250px;"
        ref="mapRef"
        @dragend="handleDrag"
        :options="{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }"
        map-type-id="terrain"
      >
      </GmapMap>
      <div
        class="location-pin"
        v-bind:style="{
          backgroundImage:
            'url(' + require('../../assets/images/mappin.png') + ')',
        }"
      ></div>

      <div class="container-btn">
        <button
          id="btnModalChat"
          class="btn-send"
          v-bind:style="{ backgroundColor: dynamicConfig.color }"
          @click="sendMessageLocation()"
        >
          <span>ส่ง</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import config from "./config.js";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "ChatMap",
  async mounted() {
    document.getElementById("mapClose").style.color = config.color;
    if (this.showMap) {
      // share location
      await this.$getLocation()
        .then((coordinates) => {
          this.myCoordinates = coordinates;
          this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
          });
        })
        .catch((error) => {
          console.log(error);
          // this.hideMap();
        });

      // use center, zoom from localStorage instead default
      if (localStorage.center) {
        this.myCoordinates = JSON.parse(localStorage.center);
      }
      if (localStorage.zoom) {
        this.zoom = parseInt(localStorage.zoom);
      }
    }
  },
  props: {
    isShowMap: {
      required: true,
      type: Boolean,
    },
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      map: null,
      myCoordinates: { lat: 13.736717, lng: 100.523186 },
      primaryColor: this.dynamicConfig.color,
      zoom: 13,
      showMap: this.isShowMap,
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    async sendMessageLocation() {
      var dataSend = {
        latitude: this.myCoordinates.lat,
        longitude: this.myCoordinates.lng,
        address: "",
        zoom: this.zoom,
      };
      if (dataSend.latitude && dataSend.longitude) {
        const _googleApiGeocoder = new this.google.maps.Geocoder();
        await _googleApiGeocoder.geocode(
          {
            location: {
              lat: parseFloat(this.myCoordinates.lat),
              lng: parseFloat(this.myCoordinates.lng),
            },
          },
          (results_, status_) => {
            if (status_ !== this.google.maps.GeocoderStatus.OK) {
              // error process here
              alert("can't get your address");
              this.hideMap();
            } else {
              dataSend.address = results_[1].formatted_address;
            }
          }
        );

        this.$emit("send-message", dataSend, 6);
        this.hideMap();
      }
    },
    handleDrag() {
      // get center and zoom level, store in localstorage
      let center = {
        lat: this.map.getCenter().lat(),
        lng: this.map.getCenter().lng(),
      };
      let zoom = this.map.getZoom();

      localStorage.center = JSON.stringify(center);
      localStorage.zoom = zoom;

      this.myCoordinates = center;
    },
    hideMap() {
      this.$emit("setShowMap", false);
      this.showMap = false;
    },
    setPlace(place) {
      this.myCoordinates.lat = place.geometry.location.lat();
      this.myCoordinates.lng = place.geometry.location.lng();
    },
  },
  watch: {
    showMap: async function() {
      if (this.showMap) {
        // share location
        await this.$getLocation().then((coordinates) => {
          this.myCoordinates = coordinates;

          this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
          });
        });

        // use center, zoom from localStorage instead default
        if (localStorage.center) {
          this.myCoordinates = JSON.parse(localStorage.center);
        }
        if (localStorage.zoom) {
          this.zoom = parseInt(localStorage.zoom);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-map {
  color: #616161;
}
.container-map {
  background-color: white;
  border-radius: 15px;
  width: 90%;
}
.modal-map-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 0.5rem 1rem;
  font-size: 20px;
  color: white;
}
.close-btn:hover {
  cursor: pointer;
}
svg {
  height: 1rem !important;
  width: auto !important;
}
.input-location-name {
  position: absolute;
  width: 79%;
  margin-top: 10px;
  margin-left: 20px;
  z-index: 1;
  display: flex;
  align-items: center;
}
.icon-search {
  position: absolute;
  left: 0;
  margin-left: 0.5rem;
}
// .icon-search svg {
//   height: 1rem !important;
//   width: auto !important;
// }
.container-btn {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px 0px 10px;
}
.btn-send {
  border: 0;
  color: white;
  border-radius: 0.5rem;
  margin-bottom: 10px;
  padding: 0.25rem;
  width: 70px;
  font-family: "DBHeavent";
  font-size: 20px;
}
.btn-send:hover {
  cursor: pointer;
}
.location-pin {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin-left: -30px;
  margin-top: -40px;
  background-size: contain;
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
  border: 1px solid #99999959; // border input
  border-radius: 8px;
  padding: 5px 10px 5px 30px;
  font-size: 20px;
  font-family: "DBHeavent";
}
.custom-input:focus {
  outline: 0px;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}
</style>
