<template>
  <div class="container-chat-box">
    <div class="card-box-body" id="chatMsgCardBody">
      <div class="chat-list">
        <div
          class="container-item"
          v-for="(item, index) in messageList"
          :key="index"
        >
          <ChatMessageItem
            @setShowMap="setShowMap"
            @send-message="sendMessage"
            :messageItem="item"
            :dynamicConfig="dynamicConfig"
          />
        </div>
        <div v-if="isSending" class="container-loader">
          <div class="loader" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isShowMap" class="modal-map-bg">
      <ChatMap
        :isShowMap="isShowMap"
        @setShowMap="setShowMap"
        @send-message="sendMessage"
        :dynamicConfig="dynamicConfig"
      />
    </div>

    <div v-if="isError">
      <div class="alert-error">
        <font-awesome-icon icon="exclamation-circle" class="icon-error" />
        {{ labelError }}
        <a @click="prepareData" class="reload-text">reload</a>
      </div>
    </div>

    <div v-if="!isError" class="card-box-footer">
      <div class="row-card-box-footer">
        <div class="col-text-area">
          <textarea
            id="chatInboxTextArea"
            class="type_msg"
            :placeholder="
              cookieLang == 'th' ? 'ส่งข้อความ...' : 'Type a message...'
            "
            v-model="textInput"
            @keypress="handlePushMessage"
            rows="1"
          ></textarea>
        </div>
        <div class="container-icon" id="iconAttach">
          <font-awesome-icon
            icon="paperclip"
            class="btn-chat"
            @click="handleBrowseImg"
            v-bind:style="{ color: dynamicConfig.color }"
          />
          <input
            type="file"
            name="chatBoxInputFile"
            id="chatBoxInputFile"
            style="display:none;"
            accept=".txt, image/png, image/jpeg, image/gif, application/pdf, video/mp4, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
            @change="preparePushFile"
          />
        </div>
        <div class="container-icon" id="iconChat">
          <font-awesome-icon
            icon="chevron-circle-right"
            class="btn-chat"
            v-bind:style="{ color: dynamicConfig.color }"
            @click="preparePushMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "./config.js";
import ChatMap from "./ChatMap.vue";
import ChatMessageItem from "./ChatMessageItem.vue";
export default {
  name: "ChatBox",
  props: {
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  components: {
    ChatMap,
    ChatMessageItem,
  },
  data() {
    return {
      textInput: "",
      attachments: [],
      messageList: [],
      isSocketOpen: false,
      chatBaseUrl: config.chatBaseUrl,
      chatBaseUrlSocket: config.chatBaseUrlSocket,
      socketKey: config.socketKey,
      isError: false,
      mySocket: null,
      // iconColor: this.dynamicConfig.color,
      // brandId: this.dynamicConfig.brandId,
      // platformId: this.dynamicConfig.platformId,
      // channelReferenceId: this.dynamicConfig.channelReferenceId,
      isSending: false,
      labelError: "Network error",
      isShowMap: false,
      cookieLang: "",
      chatTimePingPong: null,
    };
  },
  created() {
    this.cookieLang = this.$cookies.isKey("next-i18next")
      ? this.$cookies.get("next-i18next")
      : "th";
    setInterval(() => {
      if (this.$cookies.get("d-user-info")) {
        if (!this.isSocketOpen) {
          this.handleChatSocket();
        }
      }
    }, 2000);
  },
  destroyed() {
    clearInterval(this.chatTimePingPong);
    this.mySocket.close();
  },
  methods: {
    setShowMap(show) {
      this.isShowMap = show;
    },
    prepareData() {
      this.isError = false;
      this.isSending = false;
      this.labelError = "Network error";
    },
    handleChatSocket() {
      this.isSocketOpen = true;
      let pathConnection = this.chatBaseUrlSocket;
      let socket = new WebSocket(pathConnection);
      this.mySocket = socket;
      let config = {
        action: "onRegister",
        brandId: this.dynamicConfig.brandId,
        // brandId: this.$cookies.get("d-user-info").brandId,
        userGUID: this.$cookies.get("d-user-info").userId,
        socketKey: this.socketKey,
      };
      socket.onopen = () => {
        socket.send(JSON.stringify(config));
        clearInterval(this.chatTimePingPong);
        this.chatTimePingPong = setInterval(() => {
          if (socket.readyState === socket.OPEN) {
            socket.send(JSON.stringify({ action: "ping" }));
          } else {
            clearInterval(this.chatTimePingPong);
            this.isSocketOpen = false;
          }
        }, 10000);

        let countTabNow = 0;
        if (this.$cookies.isKey("d-chat-ct")) {
          countTabNow = this.$cookies.get("d-chat-ct");
        }
        if (parseInt(countTabNow) < 2) {
          const dataJoin = {
            brandId: this.dynamicConfig.brandId,
            platformId: this.dynamicConfig.platformId,
            channelReferenceId: this.dynamicConfig.channelReferenceId,
            userGUID: this.$cookies.get("d-user-info").userId,
            language: this.$cookies.isKey("next-i18next")
              ? this.$cookies.get("next-i18next")
              : "th",
          };
          // JoinMember
          this.$axios({
            method: "post",
            url: this.chatBaseUrl + "/user/joinmember",
            data: dataJoin,
            headers: {
              "API-KEY": config.apiKey,
              "Content-Type": "application/json",
            },
          });
        }
      };

      socket.onmessage = (event) => {
        let messageRes = JSON.parse(event.data);

        if (messageRes.Message) {
          if (messageRes.Message.Text == "pong") {
            // ส่งมาแบบ {Text:"pong"}
            return;
          }
        }

        if (messageRes.Message) {
          let resMsg = {};
          resMsg = {
            createdTime: messageRes.CreatedTime,
            messageResponse: {
              content: {
                content: {
                  text: messageRes.Message.Text,
                  buttons: messageRes.Message.Button,
                  flexReply: messageRes.Message.FlexReply,
                  altText: messageRes.Message.AltText,
                  label: messageRes.Message.Label,
                },
                imageList: {
                  imageUrl: messageRes.Message.ImageUrl,
                },
                responseType: messageRes.Message.ReplyType,
              },
            },
          };

          if (messageRes.Message.Image) {
            resMsg.messageResponse.content.imageList.imageUrl =
              messageRes.Message.Image;
          }

          if (messageRes.Message.FileUrl) {
            resMsg.messageResponse.content.imageList.imageUrl = [
              {
                url: messageRes.Message.FileUrl,
                name: messageRes.Message.Name,
              },
            ];
          }

          if (
            messageRes.Message.ReplyType == 9 ||
            messageRes.Message.ReplyType == 11
          ) {
            resMsg.messageResponse.content.location = messageRes.Message;
          }

          if (messageRes.Message.ReplyType == 10) {
            resMsg.messageResponse.content.content.buttons = null;
            resMsg.messageResponse.content.statusTracking = messageRes.Message;
          }

          if (messageRes.Message.ReplyType == 18) {
            resMsg.messageResponse.content.trackList = messageRes.Message;
          }

          if (messageRes.Message.ReplyType == 19) {
            resMsg.messageResponse.content.message = messageRes.Message;
          }

          this.messageList.push(resMsg);

          // scroll bar bottom
          setTimeout(() => {
            let objDiv = document.getElementById("chatMsgCardBody");
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 500);
        }
      };

      socket.onerror = () => {
        clearInterval(this.chatTimePingPong);
        this.isSocketOpen = false;
      };
      socket.onclose = () => {
        clearInterval(this.chatTimePingPong);
        this.isSocketOpen = false;
      };
    },
    handlePushMessage(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.preparePushMessage();
      }
    },
    preparePushMessage() {
      if (this.textInput.trim().length > 0) {
        let dataSend = {
          text: this.textInput,
          attachments: [],
        };
        this.sendMessage(dataSend, 1); // 1 = type message
      }
    },
    sendMessage(dataSend, type, attachments, textShow) {
      this.isSending = true;
      // scroll bar bottom
      setTimeout(() => {
        let objDiv = document.getElementById("chatMsgCardBody");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 500);

      var data = {
        brandId: this.dynamicConfig.brandId,
        platformId: this.dynamicConfig.platformId,
        channelReferenceId: this.dynamicConfig.channelReferenceId,
        userGUID: this.$cookies.get("d-user-info").userId,
        sendTime: new Date().toISOString(),
        message: {
          messageTypeId: type,
          text: dataSend.text,
          attachments: dataSend.attachments,
          location: {
            latitude: dataSend.latitude,
            longitude: dataSend.longitude,
            address: dataSend.address,
          },
        },
      };

      this.$axios({
        method: "post",
        url: this.chatBaseUrl + "/web/sendmessage",
        data: data,
        headers: {
          "API-KEY": config.apiKey,
          "Content-Type": "application/json",
        },
      })
        .then((result) => {
          if (result.data.result == 1) {
            var newMsg = {
              senderId: data.userGUID,
              createdTime: data.sendTime,
              messageResponse: {
                content: {
                  content: {
                    text: dataSend.text,
                  },
                  imageList: {
                    imageUrl: dataSend.attachments || [],
                  },
                  location: {
                    latitude: dataSend.latitude,
                    longitude: dataSend.longitude,
                    address: dataSend.address,
                    zoom: dataSend.zoom,
                  },
                  responseType: type,
                },
              },
            };

            if (textShow) {
              newMsg.messageResponse.content.content.text = textShow;
            }

            if (type == 2 || newMsg.messageResponse.content.content.text) {
              if (
                newMsg.messageResponse.content.content.text.includes("{/}") &&
                newMsg.messageResponse.content.content.text.split("{/}")
                  .length == 5
              ) {
                // Ex. 1/เอกชัยไดนาโม/7559/13.697262/100.45888
                let branchName = newMsg.messageResponse.content.content.text.split(
                  "{/}"
                )[1];

                newMsg.messageResponse.content.content.text = branchName;
              }
            }

            if (type == 5 || type == 4) {
              newMsg.messageResponse.content.imageList.imageUrl = attachments;
            }
            this.messageList.push(newMsg);
            this.textInput = "";

            // scroll bar bottom
            setTimeout(() => {
              let objDiv = document.getElementById("chatMsgCardBody");
              objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);

            this.isSending = false;
          } else {
            this.handleLoadError();
          }
        })
        .catch(() => {
          this.handleLoadError();
        });
    },
    handleBrowseImg() {
      document.getElementById("chatBoxInputFile").click();
    },
    preparePushFile(e) {
      if (e.target.files.length) {
        let dataSend = {
          text: this.textInput,
          attachments: [],
        };
        var fileAttachments = [];
        var vdoAttachments = [];

        var fileInput = document.getElementById("chatBoxInputFile").files;

        e.target.files.forEach((file, i) => {
          if (file.size < 4194304) {
            //Max 4MB
            let readerFile = new FileReader();
            readerFile.readAsDataURL(file);
            readerFile.onload = () => {
              this.isSending = true;

              this.$axios({
                method: "post",
                url: this.chatBaseUrl + "/upload/Save",
                data: {
                  base64: readerFile.result,
                },
                headers: {
                  "API-KEY": config.apiKey,
                  "Content-Type": "application/json",
                },
              })
                .then((result) => {
                  if (result.data.result == 1) {
                    dataSend.attachments.push(result.data.detail.url);
                    if (
                      this.getExtension(fileInput[i].name).toLowerCase() ==
                        "jpg" ||
                      this.getExtension(fileInput[i].name).toLowerCase() ==
                        "png" ||
                      this.getExtension(fileInput[i].name).toLowerCase() ==
                        "gif" ||
                      this.getExtension(fileInput[i].name).toLowerCase() ==
                        "jpeg"
                    ) {
                      if (
                        dataSend.attachments.length == e.target.files.length
                      ) {
                        this.sendMessage(dataSend, 3); // 3 = type Image
                      }
                    } else if (this.getExtension(fileInput[i].name) == "mp4") {
                      vdoAttachments.push({
                        link: result.data.detail.url,
                        name: fileInput[i].name,
                      });
                      if (vdoAttachments.length == e.target.files.length) {
                        this.sendMessage(dataSend, 4, vdoAttachments); // 4 = type Video
                      }
                    } else {
                      fileAttachments.push({
                        link: result.data.detail.url,
                        name: fileInput[i].name,
                      });
                      if (fileAttachments.length == e.target.files.length) {
                        this.sendMessage(dataSend, 5, fileAttachments); // 5 = type File
                      }
                    }
                  } else {
                    this.labelError = result.data.message;

                    if (!this.labelError) {
                      this.labelError = "Can't send this file.";
                    }
                    this.handleLoadError();
                  }
                })
                .catch(() => {
                  this.handleLoadError();
                });
            };
          }
        });
      }
    },

    handleLoadError() {
      this.isError = true;
    },
    getExtension(filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    },
  },
};
</script>

<style lang="scss" scoped>
.container-chat-box {
  height: 100%;
  width: 100%;
  background-color: #eeeded;
  padding: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0px;
}
.container-item {
  margin: 0.5rem 1rem 0rem 0.8rem;
  padding-bottom: 5px;
}
.container-loader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
}
.loader {
  border: 10px solid #dfd9d9;
  border-radius: 50%;
  border-top: 10px solid #757575;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin-right: 1.5rem;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card-box-body {
  background-color: #eeeded;
  height: 60vh;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
}
.card-box-footer {
  position: relative;
  background-color: white;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
}
.row-card-box-footer {
  display: flex;
  justify-content: center;
  padding: 7px 0px;
}
.col-text-area {
  width: 75%;
  display: flex;
}
.type_msg {
  background-color: inherit !important;
  overflow: hidden;
  resize: none;
  border: none;
  width: 100%;
  height: 20px;
  font-size: 20px;
  font-family: "DBHeavent";
  padding: 0px 2px;
}
.btn-chat {
  height: 100%;
  font-size: 32px;
  padding-left: 10px;
}
.btn-chat:hover {
  cursor: pointer;
}
.type_msg:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.reload-text {
  font-weight: bold;
  color: #d3545d;
  font-size: 20px;
  margin-left: 5px;
}
.reload-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
.modal-map-bg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 10px;
}
.container-icon {
  width: 10%;
  padding: 0;
  height: 24px;
}
.alert-error {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #f8d7da;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  margin: 0px;
  color: #595a5c;
}
.icon-error {
  margin-right: 5px;
  height: 1rem !important;
  width: auto !important;
}
.chat-list {
  padding-top: 10px;
}
textarea::-webkit-input-placeholder {
  color: #bebebe;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #bebebe;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #bebebe;
}

textarea:-ms-input-placeholder {
  color: #bebebe;
}

textarea::placeholder {
  color: #bebebe;
}

// vertical
@media (max-width: 767.98px) {
  .card-box-body {
    height: calc(100vh);
    z-index: 10;
    overflow-y: auto;
    overflow-x: hidden;
    position: static;
  }
  .chat-list {
    max-height: none;
    padding-top: 10px;
    overflow: visible;
    padding-bottom: 3.6rem;
  }
  .card-box-footer {
    position: fixed;
    background-color: white;
    width: 100%;
    bottom: 0;
    padding: 7px 20px 7px 20px;
    border-radius: 0px;
  }
  .row-card-box-footer {
    padding: 0px;
  }
  .modal-map-bg {
    border-radius: 0;
  }
  .alert-error {
    position: fixed;
    bottom: 0;
    border-radius: 0;
    width: 100%;
    margin: 0px;
  }
  .container-item {
    margin-bottom: 1rem;
    padding-bottom: 0px;
  }
  .type_msg {
    padding: 0px 2px;
  }
}

::-webkit-scrollbar {
  width: 13px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgb(167, 167, 167);
}
</style>
