<template>
  <div>
    <div
      class="container-frame"
      v-if="messageItem.messageResponse.content.responseType == 3"
    >
      <VueSlickCarousel v-bind="slickOptions">
        <div
          v-for="(content, i) in messageItem.messageResponse.content.imageList"
          :key="i"
          class="msg_wrap"
        >
          <div class="wrap-product-img">
            <div
              class="product-img"
              v-bind:style="{
                backgroundImage: `url(&quot;${content}&quot;)`,
              }"
            ></div>
          </div>
          <div class="container-item">
            <span
              v-html="messageItem.messageResponse.content.content.text"
              class="msg-card-sub-title"
            >
              {{ messageItem.messageResponse.content.content.text }}
            </span>
          </div>
          <div class="container-btn container-item">
            <button
              v-for="(btn, j) in messageItem.messageResponse.content.content
                .buttons"
              :key="j"
              type="button"
              class="list-btn"
              v-bind:style="{ backgroundColor: primaryColor }"
              @click="
                () => {
                  handleClickButton(btn.Action, btn.Label, btn.Data);
                }
              "
            >
              {{ btn.Label }}
            </button>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
      </VueSlickCarousel>
    </div>

    <div class="container-frame" v-else>
      <VueSlickCarousel v-bind="slickOptions">
        <div
          v-for="(content, i) in messageItem.messageResponse.content.imageList"
          :key="i"
          class="msg_wrap"
        >

          <div class="wrap-product-img">
            <div
              class="product-img"
              v-bind:style="{
                backgroundImage: `url(&quot;${content}&quot;)`,
              }"
            ></div>
          </div>

          <div class="container-item">
            <span
              v-html="messageItem.messageResponse.content.content.text"
              class="msg-card-sub-title"
            >
              {{ messageItem.messageResponse.content.content.text }}
            </span>
          </div>
          <hr />

          <div
            v-for="(btn, j) in messageItem.messageResponse.content.content
              .buttons"
            :key="j"
            class="container-btn container-item"
          >
            <button
              v-if="btn.Action != 8"
              type="button"
              class="list-btn"
              v-bind:style="{ backgroundColor: primaryColor }"
              @click="
                () => {
                  handleClickButton(btn.Action, btn.Label, btn.Data);
                }
              "
            >
              {{ btn.Label }}
            </button>

            <a
              v-else
              class="list-btn btn-tel"
              v-bind:style="{ backgroundColor: themeColor }"
              :href="`tel:${btn.Label}`"
            >
              {{ btn.Label }}
            </a>
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="custom-arrow">
            {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
          </div>
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import config from "../config.js";

export default {
  name: "ChatBoxTextImg",
  components: {
    VueSlickCarousel,
  },
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      primaryColor: this.dynamicConfig.color,
      slickOptions: {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        dots: false,
        initialSlide: 0,
        autoplay: false,
      },
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.msg_wrap {
  overflow-wrap: break-word;
  color: #595a5c;
  font-size: 20px;
}
.wrap-product-img {
  width: auto;
  height: auto;
  padding: 0px 12px 0px 12px;
}
.product-img {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
}
.title-black {
  color: #3a3937;
  justify-content: flex-start;
  margin-bottom: 8px;
}
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.msg-card-sub-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
}
.slick-prev:before,
.slick-next:before {
  color: rgba(22, 39, 74, 0.4);
  font-size: 25px;
}
.slick-next {
  margin-right: 5px;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.container-text-title {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.container-text-title-top {
  margin-top: 0px;
}
.container-btn {
  display: flex;
  flex-flow: column;
  margin-top: auto;
}
.list-btn {
  border-radius: 0.5rem;
  margin-bottom: 5px;
  color: white;
  border: 0px;
  padding: 0.4rem;
  font-size: 20px;
  font-family: "DBHeavent";
}
.list-btn:hover {
  cursor: pointer;
}
.container-frame {
  padding-top: 10px;
  padding-bottom: 5px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-tel {
  text-decoration-line: none;
  text-align: center;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89,90,92, 0.25);
  outline: none;
}
</style>
