<template>
  <div>
    <!-- Type 2 Text Button -->
    <div
      class="container-frame"
      v-if="messageItem.messageResponse.content.responseType == 2"
    >
      <div
        v-for="(btn, j) in messageItem.messageResponse.content.content.buttons"
        :key="j"
        class="column container-item"
        v-show="btn.Type == 2"
      >
        <button
          type="button"
          class="list-btn"
          v-bind:style="{ backgroundColor: '#b9b8b8' }"
          @click="
            () => {
              handleClickButton(btn.Action, btn.Label, btn.Data);
            }
          "
        >
          {{ btn.Label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import config from "../config.js";

export default {
  name: "ChatBoxButtonQuikReply",
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
    dynamicConfig: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      themeColor: this.dynamicConfig.color,
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-frame {
  display: flex;
  flex-flow: wrap;
}
.container-item {
  padding-left: 0.5rem;
}
.list-btn {
  border-radius: 0.5rem;
  margin-top: 5px;
  color: #fff;
  border: 0px;
  padding: 0.4rem;
  font-size: 18px;
  font-family: "DBHeavent";
}
.list-btn:hover {
  cursor: pointer;
}
.column {
  display: flex;
  flex-flow: column;
}
.btn-tel {
  text-decoration-line: none;
  text-align: center;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}
</style>
